export const environment = {
  production: true,

  version: '2024.10.0',
  versionHash: '95a94f8030bdeb2f3a342b42850a17e0ee8f990c',

  api: {
    basePath: 'https://api.sugardaddy-dev.synapse-intra.com',
  },

  stream: {
    basePath: 'wss://api.sugardaddy-dev.synapse-intra.com',
  },

  cms: {
    basePath: 'https://help.sugardaddy-dev.synapse-intra.com',
  },

  site: {
    noindex: 'yes',
  },

  payment: {
    usePaymentServiceMock: false,
    paidy: {
      apiKey: 'pk_test_edplhs06uv2ahi2l6v4odru8sh',
    }
  },

  amplify: {
    Auth: {
      identityPoolId: '',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_8chHBwHiE',
      userPoolWebClientId: '7h5nkr30mr7qbg2it099521b9t',
    },
  },

  recatpcha: {
    siteKey: '6LcAuVojAAAAANRkBpGBKQJecP7EIjtPMeMuR0sb',
  },

  analysis: {
    gtagId: 'G-LH216B2FW9',
    gtagId2: 'GTM-K6KF47NZ',
    gtm_auth: 'ma-SaVDIMCaSOZAep3xE0A',
    gtm_preview: 'env-4',
    gtm_resource_path: '',
    pixelId: '1976549862601304',
    uwtId: 'o6019',
  },
};

export const ENV_MODULES: any[] = [];

export const ENV_PROVIDERS: any[] = [];
